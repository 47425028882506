<template>
  <div class="container-page" v-if="item">
    <p class="title-inner">Карточка экспортного потенциала по услугам</p>

    <div class="row">
      <div class="form-group col-6 required">
        <ComponentInput
          label="Вид услуги"
          :select2Options="serviceList"
          v-model="item.serivceTypeId"
          :select2Settings="select2SettingsNoClear"
          type="select2"
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-6 required">
        <ComponentInput label="Потенциал прироста, млн $ США" v-model="item.value" type="number" required />
      </div>
      <div class="form-group col-6 required">
        <ComponentInput label="Фактический потенциал, $ США" v-model="item.exportValue" type="number" required />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-6 required">
        <ComponentInput
          label="Страна-экспортер"
          :select2Options="countries"
          v-model="item.fromCountryId"
          :select2Settings="select2SettingsNoClear"
          type="select2"
          readonly
        />
      </div>
      <div class="form-group col-6 required">
        <ComponentInput
          label="Страна-импортер"
          :select2Options="countries"
          v-model="item.toCountryId"
          :select2Settings="select2SettingsNoClear"
          type="select2"
          required
        />
      </div>
    </div>
    <!-- <div class="checkboxInput">
            <input type="checkbox" v-model="item.isActive" id="chkActive">
            <label for="chkActive" class="form-label">Запись активна</label>
        </div> -->

    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import Constants from '@/common/constants';

  import CountryApi from '@/modules/nsi/api/country';

  import ComponentInput from '../../../common/components/ComponentInput';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import Api from '../api/exportPotentialService.js';

  export default {
    name: 'ExportPotentialServiceCard',
    components: { ComponentInput, ButtonStock },
    data() {
      return {
        id: this.$route.params.id,
        item: {
          serviceId: null,
          fromCountryId: 235,
          toCountryId: null,
        },
        countries: [],
        serviceList: [],
        select2SettingsNoClear: Constants.select2SettingsNoClear,
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          Api.find(this.id).then((response) => {
            this.item = response.data;
          });
        }
      },
      loadDictionaries() {
        CountryApi.getList().then((response) => {
          this.countries = response.data;
        });
        Api.getServiceList().then((response) => {
          this.serviceList = response.data.map((x) => {
            return { text: x.name, id: x.id };
          });
        });
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        Api.update(this.id, this.item)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            this.$router.push({ name: 'ExportPotentialService' });
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'ExportPotentialService' });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {};
    },
  };
</script>

<style lang="scss" scoped></style>
